@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Lato:wght@300&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000100;
}

a:hover {
  color: #94c5cc;
  cursor: pointer;
}

a {
  color: #000100;
  text-decoration: none;
}

hr {
  border: 0;
  border-top: 1px solid #b4d2e7;
  margin: 0;
}

p {
  line-height: 1.9;
  margin: 0;
  font-size: 16px;
}

button {
  background: 0;
  border: 0;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.gray-link {
  padding-left: 32px;
  padding-right: 32px;
  color: #6f727b;
}

.header-link:hover {
  padding-bottom: 8px;
  border-bottom-color: #94c5cc;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.header-link-selected {
  padding-bottom: 8px;
  border-bottom-color: #94c5cc;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.overlay {
  height: 100%;
}

.overlay img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 1;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.overlay:hover img {
  opacity: 0.03;
}
